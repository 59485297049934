// External dependencies
import { graphql } from 'gatsby';
import { Helmet, useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';

// Local dependencies
import { RootState } from '@app/redux/store';
import UserDetails from '@entities/users/ui/userDetails/UserDetails';
import UserLoggedIn from '@feautures/ui/userLoggedIn/UserLoggedIn';
import Layout from '@shared/ui/layout/WebLayout';
import { pagePermissions } from '../../../../PagePermissions';

export default function ProAccountDetailsPage({ data }) {
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = data;
  const { t } = useI18next();

  const { user } = useSelector((state: RootState) => state.getUser);

  return (
    <Layout>
      <Helmet>
        <title>
          {titleTemplate.replace(
            '%s',
            `${t('adminPanelUserDetails')} ${t(user?.__typename)} ${
              user?.lastName
            } ${user?.firstName}`,
          )}
        </title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <UserLoggedIn roles={pagePermissions.userDetails}>
        <UserDetails />
      </UserLoggedIn>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
